import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tuple-types"
    }}>{`Tuple Types`}</h1>
    <hr></hr>
    <p>{`The same as `}<a parentName="p" {...{
        "href": "/docs/primitive-types"
      }}>{`Primitive Types`}</a>{` and `}<a parentName="p" {...{
        "href": "/docs/primitive-types"
      }}>{`Object Types`}</a>{`, Hegel provides support for usage array literal as type, and this array literal types called Tuples. Tuple types allow you to define an array with a fixed number of elements with known types. The syntax fo tuple types is `}<inlineCode parentName="p">{`[type1, type2, type3]`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const tuple: [string, number] = ["", 0];
`}</code></pre>
    <h2 {...{
      "id": "getting-element-from-tuple"
    }}>{`Getting element from Tuple`}</h2>
    <p>{`Unlike `}<a parentName="p" {...{
        "href": "/docs/array-types"
      }}>{`Array Types`}</a>{` Tuple will return strict type which is placed at position in tuple type definition and will show an error, if you try to access out of tuple range.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const tuple: [string, number] = ["", 0];

// first type is "string"
const first = tuple[0];

// second type is "number"
const second = tuple[1];

// Error: Property "2" does not exist in "[string, number]"
const third = tuple[2];
`}</code></pre>
    <p>{`Also, unlike `}<a parentName="p" {...{
        "href": "/docs/array-types"
      }}>{`Array Types`}</a>{`, Tuples have strict defined type for length and as result they cannot mutate "length" property in a tuple type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const tuple: [string, number] = ["", 0];

// length type is "2"
const length = tuple.length;

// Error: Type "3" is incompatible with type "2"
tuple.length = 3;
`}</code></pre>
    <h2 {...{
      "id": "tuple-methods-and-mutation"
    }}>{`Tuple methods and mutation`}</h2>
    <p>{`As you may understand, Hegel provides the same methods for Tuples Types as for `}<a parentName="p" {...{
        "href": "/docs/array-types"
      }}>{`Array Types`}</a>{`, but with one exception. If you try to use any of methods from Array which will mutate source tuple (`}<a parentName="p" {...{
        "href": "https://doesitmutate.xyz/"
      }}>{`https://doesitmutate.xyz/`}</a>{`) you will have an error.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const numbers: [number, number, number] = [1, 2, 3];
const plusOne = numbers.map((a) => a + 1);
const lessThan3 = numbers.filter((a) => a < 3);

// Error: Property "reverse" does not exist in "[number, number, number]"
const reversed = numbers.reverse();
`}</code></pre>
    <h2 {...{
      "id": "subtyping"
    }}>{`Subtyping`}</h2>
    <p>{`The same as `}<a parentName="p" {...{
        "href": "/docs/array-types"
      }}>{`Array Types`}</a>{` Tuple types have invariant nature, so you can't assign to one tuple another with wider element type or with greater or less elements count.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const source: [number, number] = [0, 0];

// Error: Type "[number, number]" is incompatible with type "[number | string, number]"
const widerByElementType: [number | string, number] = source;

// Error: Type "[number, number]" is incompatible with type "[number, number, number]"
const widerByElementsCount: [number, number, number] = source;

// Error: Type "[number, number]" is incompatible with type "[number]"
const narrowerByElementsCount: [number] = source;
`}</code></pre>
    <p>{`And also tuples don’t match array types:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const source: [number, number] = [0, 0];

// Error: Type "[number, number]" is incompatible with type "Array<number>"
const array: Array<number> = source;
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      